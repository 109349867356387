
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import config from 'ui/configs/mui'

export const Theme = ({children}) => {
    return(
        <ThemeProvider theme={config}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default Theme