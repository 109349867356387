
import { Link as RouterLink } from "react-router-dom"

import Link from "@mui/material/Link"

export default ({
    title,
    to,
    onClickF = ()=>{},
}) => {
    return(
        <Link
            //component={ RouterLink }
            href={ to }
            sx={{
                fontSize: "1.8rem",
                textDecoration: "none",
                display: "block",
                color: "text.primary",
                "&:hover": {
                    color: "primary.main"
                },
                textAlign: "center",
                transition: "color .2s ease",
            }}
            onClick={ onClickF }
        >
            { title }
        </Link>
    )
}