
import {
    USDT_CONTARCT_ADDRESS,
} from "ui/configs/main"

export const getBalances = async ( walletAddress ) => {
    // fetchData + getBalances

    if( !walletAddress ) {
      throw new Error("0011");
    }

    const response = await fetch(
        `https://apilist.tronscan.org/api/account?address=${walletAddress}`
    );
    if (!response.ok) {
        throw new Error("0012");
    }
    const fetchData = await response.json();

    ///////////////////

    const usdtToken = fetchData.trc20token_balances.find(
      (token) => token.tokenId === USDT_CONTARCT_ADDRESS
    );
    return {
      trxBalance: fetchData.balance || 0,
      usdtBalance: usdtToken ? usdtToken.balance : 0,
    };
}

export default getBalances