
import {  useSelector, } from "react-redux"
import { useTranslation } from 'react-i18next';

import {
    walletConnectionRejectedSelector,
} from "store"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogCloseBtn from "../DialogCloseBtn";
import MainBtn from "../btns/MainBtn";
import { Typography } from "@mui/material";

import useUI from 'ui/hooks/useUI';


export const WalletConnectionRejected = () => {

    const { t } = useTranslation()

    const { stopWalletConnectionRejectedDialog, } = useUI()

    const isDialog = useSelector( walletConnectionRejectedSelector() )

    return(
        <Dialog
            open={ isDialog }
            onClose={ ()=>{
                stopWalletConnectionRejectedDialog()
            } }
            fullWidth={ true }
        >
            <DialogCloseBtn
                sx={{
                    color: "#000000",// "primary.contrastText"
                }}
                onClickF={ ()=>{
                    stopWalletConnectionRejectedDialog()
                } }
            />
            <DialogTitle sx={{
                /*
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderBottom: "1px solid",
                borderColor: "divider"*/
            }}>
                { t("dialogs.wallet_connection_rejected.title") }
            </DialogTitle>
            <DialogContent sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingTop: "0px !important"
            }}>
                <Typography variant="body2">
                    { t("dialogs.wallet_connection_rejected.text") }
                </Typography>
                <MainBtn
                    title="OK"
                    color="primary"
                    variant="contained"
                    onClickF={ ()=>{
                        stopWalletConnectionRejectedDialog()
                    } }
                />
            </DialogContent>
        </Dialog>
    )
}

export default WalletConnectionRejected