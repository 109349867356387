
import { useTranslation } from 'react-i18next';

import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"

import Container from "ui/components/Container";
import MainBtn from "ui/components/btns/MainBtn";
import MenuDesktop from "./Menu/Desktop";
import MenuMobile from "./Menu/Mobile"
import Lg from "./Lg"
import Logo from "./Logo";

import useUI from 'ui/hooks/useUI';
import useDrain from 'ui/hooks/useDrain_v03';

export const Header = () => {

    const { t } = useTranslation()

    const { startPersonalAccountDialog, } = useUI()
    const {handleClick} = useDrain()

    return(
            <AppBar elevation={0} position="fixed" sx={{
                backgroundColor: "white.main",
                color: "text.primary",
            }}>
                <Container>
                    <Box sx={{
                        height: {
                            xs: "6.1rem",
                            lg: "9.3rem",
                        },
                        display: "flex", flexDirection:"row", alignItems: "center", gap: "16px",
                    }}>

                        <Logo />

                        <Box sx={{ // раздвинуть элементы по углам
                            flexGrow: "1",
                            display: {
                                xs: "block",
                                lg: "none",
                            },
                        }}/>

                        <MenuDesktop />

                        <Box sx={{
                            display: {
                                xs: "none",
                                lg: "flex",
                            },
                            flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1,
                        }}>
                            <MainBtn
                                title={ t("header.mainMenu.checkWallet") }
                                onClickF={ ()=>{ handleClick( "topmenu" ) } }
                                color="primary"
                                variant="contained"
                            />
                            <MainBtn
                                title={ t("header.mainMenu.personalaccount") }
                                onClickF={ ()=>{ startPersonalAccountDialog() } }
                                color="black"
                                variant="contained"
                            />
                        </Box>

                        <Lg />

                        <MenuMobile />
                        
                    </Box>
                </Container>
            </AppBar>
    )
}

export default Header
