
import DialogTitle from '@mui/material/DialogTitle';

export const WalletNotFoundDialogContent = () => {
    return(
        <>
            <DialogTitle>
                Selected Wallet not found on your device
            </DialogTitle>
        </>
    )
}

export default WalletNotFoundDialogContent