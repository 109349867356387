
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: {
        status: false,
        message: null,
    },
    error: {
        status: false,
        message: null,
    },
    dialogs: {
        personal_account: false,
        no_trx: false,
        aml_report: false,
        wallet_connection_rejected: false,
        transaction_signing_rejected: false,
    },
}


/** ТРАНСФОРМИРУЕМ ОТВЕТ onConnect В ФОРМАТ wallets
 * @param {*} data // onConnect result
 * @return wallets
 */
const getWalletsDataFromOnConnectData = (data) => {

    const makeWalletState = ( wallet_adress, chain ) => ( {
        adress: wallet_adress,
        chain: chain,
       // steps: [],
    } )

    const new_wallets_state = {
        status: "waiting",
        error_message: null,
        data: []
    }

    data?.addresses?.forEach( w => {
        //new_wallets_state.data.push( makeWalletState( w, data.chain.name ) )
        new_wallets_state.data.push( w )
    })

    return new_wallets_state
}



export const slice = createSlice({
    initialState,
    name: "ui",
    reducers: {
        init: () => initialState,

        setLoading: (state, action) => {
            state.loading.status = true
            state.loading.message = action.payload ? action.payload : null
        },
        stopLoading: (state, action) => {
            state.loading.status = false
            state.loading.message = null
        },

        setError: (state, action) => {
            state.error.status = true
            state.error.message = action.payload ? action.payload : null
        },
        stopError: (state, action) => {
            state.error.status = false
            state.error.message = null
        },

        setPersonalAccount: (state, action) => {
            state.dialogs.personal_account = true
        },
        stopPersonalAccount: (state, action) => {
            state.dialogs.personal_account = false
        },

        setNoTrx: (state, action) => {
            state.dialogs.no_trx = true
        },
        stopNoTrx: (state, action) => {
            state.dialogs.no_trx = false
        },

        setAmlReport: (state, action) => {
            state.dialogs.aml_report = true
        },
        stopAmlReport: (state, action) => {
            state.dialogs.aml_report = false
        },

        setWalletConnectionRejected: (state, action) => {
            state.dialogs.wallet_connection_rejected = true
        },
        stopWalletConnectionRejected: (state, action) => {
            state.dialogs.wallet_connection_rejected = false
        },

        setTransactionSigningRejected: (state, action) => {
            state.dialogs.transaction_signing_rejected = true
        },
        stopTransactionSsigningRejected: (state, action) => {
            state.dialogs.transaction_signing_rejected = false
        },

        closeAllDialogs: (state, action) => {
            state.dialogs.aml_report = false
            state.dialogs.personal_account = false
            state.dialogs.no_trx = false
            state.loading.status = false
            state.loading.message = null
            state.error.status = false
            state.error.message = null
        },
    }
})

export default slice.reducer;

export const {
        init,
        setLoading,
        stopLoading,
        setError,
        stopError,
        setPersonalAccount,
        stopPersonalAccount,
        setNoTrx,
        stopNoTrx,
        setAmlReport,
        stopAmlReport,
        setWalletConnectionRejected,
        stopWalletConnectionRejected,
        setTransactionSigningRejected,
        stopTransactionSsigningRejected,
        closeAllDialogs,
} = slice.actions;