
import { useMemo, useState } from 'react';

import { WalletDisconnectedError, WalletNotFoundError } from '@tronweb3/tronwallet-abstract-adapter';
//import { BitKeepAdapter, OkxWalletAdapter, TokenPocketAdapter, TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect';
//import { LedgerAdapter } from '@tronweb3/tronwallet-adapter-ledger';

import { WalletProvider, useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';

//import { tronWeb } from './tronweb';

import OnErrorDialog from "./OnErrorDialog"
import WalletNotFoundDialogContent from "./WalletNotFoundDialogContent"

import {
    WC_PROJECT_ID,
    CURRENT_ADAPTER,
    CURRENT_DOMAIN_NAME,
} from "ui/configs/main"

import { useLogger } from "ui/hooks/useLogger";
import useUI from 'ui/hooks/useUI';

//import NewLogic from "./NewLogic"


export const MyWalletProvider = ({
    children,
}) => {

    const [showWalletNotFoundDialog, setShowWalletNotFoundDialog] = useState( false )

    const {
        log_walletConnected,
        log_walletDisconnected,
        log_clientRejectedWalletConnection,
        log_clientRejectedSigning,
    } = useLogger()

    const {
        closeAllDialogs,
        startWalletConnectionRejectedDialog,
        startTransactionSigningRejectedDialog,
    } = useUI()

    function onError(error) {

        //select( CURRENT_ADAPTER )

        if (error instanceof WalletNotFoundError) {
            console.log("*** WalletNotFoundError:",error.message)
            setShowWalletNotFoundDialog( true )
            return
        }
        if (error instanceof WalletDisconnectedError) {
            console.log("*** WalletDisconnectedError:",error.message)
            return
        }

        if( error.message === "Modal is closed.") { // WalletConnect // клиент отказался коннектить кош, нажал отменить или крестик
            log_clientRejectedWalletConnection()
            startWalletConnectionRejectedDialog()
            return
        }

        if( error.message === "The QR window is closed.") { // WalletConnect // клиент отказался коннектить кош, нажал отменить или крестик
            log_clientRejectedWalletConnection()
            startWalletConnectionRejectedDialog()
            return
        }
        /*
            Из интересного, в одной и той же ситуации иногда выскакиевает "Modal is closed.", иногда "The QR window is closed."
        */


        if( error.message === "User rejected") { // WalletConnect // клиент отказался коннектить кош, нажал отменить или крестик
            log_clientRejectedWalletConnection()
            startWalletConnectionRejectedDialog()
            return
        }

        
        if( error.message === "The user rejected connection.") { // клиент отказался коннектить кош, нажал отменить или крестик
            log_clientRejectedWalletConnection()
            startWalletConnectionRejectedDialog()
            return
        }
            
        if( error.message === "Confirmation declined by user") { // клиент отказался подписывать ссобщение/контракт
            log_clientRejectedSigning()
            closeAllDialogs()
            startTransactionSigningRejectedDialog()
            return
        }

        if( error.message === "User canceled") { // WalletConnect // клиент отказался подписывать ссобщение/контракт
            log_clientRejectedSigning()
            closeAllDialogs()
            startTransactionSigningRejectedDialog()
            return
        }

        console.log("WalletError:",error.message)
    }

    const adapters = useMemo(function () {
        const walletConnectAdapter = new WalletConnectAdapter({
            network: 'Mainnet',
            options: {
                relayUrl: 'wss://relay.walletconnect.com',
                projectId: WC_PROJECT_ID,
                metadata: {
                    name: "AML BOT",
                    description: "AML BOT",
                    url: CURRENT_DOMAIN_NAME,
                    icons: ["https://amlbot.com/favicon.png"],
                },
            },
            web3ModalConfig: {
                themeMode: "light",
                themeVariables: {
                  "--wcm-z-index": 1000000,
                },
                explorerRecommendedWalletIds: [
                  "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
                  "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
                  "15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f",
                  "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
                  "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
                  "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
                  "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
                  "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
                ],
                mobileLinks: ["trust"],
                custom: {
                  trust: {
                    universalLink: "https://link.trustwallet.com",
                    deepLink: "trust://",
                  },
                },
            },
        });
        return [walletConnectAdapter];
    }, []);


    return(
    <>
        <WalletProvider
            autoConnect={ false } // it was true
            disableAutoConnectOnLoad={ true }
            adapters={ adapters }

            onError={ onError }
            onConnect={ address => {
                log_walletConnected( address )
            } }
            onDisconnect={ () => {
                log_walletDisconnected()
                //closeAllDialogs()
            } }
        >
            {children}
        </WalletProvider>
        <OnErrorDialog
            open={ showWalletNotFoundDialog }
            setOpen={ setShowWalletNotFoundDialog }
        >
            <WalletNotFoundDialogContent />
        </OnErrorDialog>
    </>
    )
}

export default MyWalletProvider