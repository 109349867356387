
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PreLoaderView from "ui/components/PreLoaderView"
//import ViewLoading from "./ViewLoading"

import {
    useGetTrackersDataQuery,
} from "dal"

import {
    client_status_tracker_selector,
    set_clients_tmp_id,
} from "store"


export const getId = () => {
    return Array.from({ length: 16 }, () =>
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
            Math.floor( Math.random() * 62 )
        )
    ).join("");
}

export const ClientPrepare = ({ children }) => {

    /* ORIGINAL
        const storageKey = "uniqueId";
        let uniqueId = localStorage.getItem(storageKey);

        if (!uniqueId) {
        uniqueId = Array.from({ length: 16 }, () =>
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
            Math.floor(Math.random() * 62)
            )
        ).join("");
        localStorage.setItem(storageKey, uniqueId);
        }
    */

    const dispatch = useDispatch()

    useEffect( ()=>{
        dispatch( set_clients_tmp_id( getId() ) )
    },[] )

    // запускаем запрос треккера
    // результат в сторе
    // после выполнение запустится запрос создания клиента в апишке
    // логика прописана в api.tracker.slice.js
    const tracker_result = useGetTrackersDataQuery()

    // следим за статусом создания клиента в сторе 
    const status = useSelector( client_status_tracker_selector() )

    if( status === "not-done" || status === "pending" ) {
        return(
            <PreLoaderView /* message="UI Loading..." */ />
        )
    }
        
    return children
}

export default ClientPrepare