
import Box from "@mui/material/Box"

export const Wrapper = ({ children }) => {
    return(
        <Box className="_wrapper" sx={{  
            minHeight: "100vh",
            display: "flex", flexDirection: "column",
        }}>
            { children }
        </Box>
    )
}

export default Wrapper