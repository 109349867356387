
import { useSelector, } from "react-redux"
import { useTranslation } from 'react-i18next';

import {
    amlReportSelector,
} from "store"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogCloseBtn from "../DialogCloseBtn";
import MainBtn from "../btns/MainBtn";
import { Alert, Typography } from "@mui/material";

import useUI from 'ui/hooks/useUI';


export const AmlReport = () => {

    const { t } = useTranslation()

    const { stopAmlReportDialog, } = useUI()

    const isDialog = useSelector( amlReportSelector() )

    return(
        <Dialog
            open={ isDialog }
            onClose={ ()=>{
                stopAmlReportDialog()
            } }
            fullWidth={ true }
        >
            <DialogCloseBtn
                sx={{
                    color: "#000000",// "primary.contrastText"
                }}
                onClickF={ ()=>{
                    stopAmlReportDialog()
                } }
            />
            <DialogTitle sx={{
                /*
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderBottom: "1px solid",
                borderColor: "divider"*/
            }}>
                { t("dialogs.aml_report.title") }
            </DialogTitle>
            <DialogContent sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingTop: "0px !important"
            }}>
                <Typography variant="body2">
                    { t("dialogs.aml_report.text") }
                </Typography>
                <Alert severity="success" sx={{
                    fontSize: "2rem",
                    fontWeight: "500",
                }}>
                    AML-Risk: 13%
                </Alert>
                <a href={"./report_887733256773.pdf"} download="report_887733256773" target='_blank'>
                    <MainBtn
                        title={ t("dialogs.aml_report.btn") }
                        variant="contained"
                    />
                </a>
            </DialogContent>
        </Dialog>
    )
}
/*
<p class="subtitle">
          AML-Risk check succeeded. Your assets in safe!<br />Wallet Address:
          <span class="wallet-address">undefined</span><br />AML-Risk:
          <span class="aml-risk">7.16%</span> (A-Tier)
        </p>
*/

export default AmlReport