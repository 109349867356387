
export const data = [
    {
        title: "header.mainMenu.home",
        to: "#home",
    },
    {
        title: "header.mainMenu.analize",
        to: "#analize",
    },
    {
        title: "header.mainMenu.price",
        to: "#pricing",
    },
    {
        title: "header.mainMenu.aboutus",
        to: "#aboutus",
    },
    {
        title: "header.mainMenu.faq",
        to: "#faq",
    },
]

export default data