
import { useDispatch, useSelector, } from "react-redux"
import { useTranslation } from 'react-i18next';

import {
    ui_stopError,
    errorStatusSelector,
    errorMessageSelector,
} from "store"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

import DialogCloseBtn from "../DialogCloseBtn";

export const ErrorDialog = () => {

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const isError = useSelector( errorStatusSelector() )
    const errorMessage = useSelector( errorMessageSelector() )

    return(
        <Dialog
            open={ isError }
            onClose={ ()=>{
                dispatch( ui_stopError() )
            } }
        >
            <DialogCloseBtn
                sx={{
                    color: "error.contrastText"
                }}
                onClickF={ ()=>{
                    dispatch( ui_stopError() )
                } }
            />
            <DialogTitle sx={{
                backgroundColor: "error.main",
                color: "error.contrastText"
            }}>
                { t( "dialogs.oops.title" ) }
            </DialogTitle>
            <DialogContent sx={{
                backgroundColor: "error.light",
                color: "error.contrastText",
            }}>
                { errorMessage ? errorMessage : t( "dialogs.oops.content" ) }
            </DialogContent>
            <Box sx={{
                fontSize: "1.4rem",
                backgroundColor: "error.main",
                color: "error.contrastText",
                paddingX: "3rem",
                paddingY: "1.5rem",
            }}>
                { t( "dialogs.oops.footer1" ) }<br />
                { t( "dialogs.oops.footer2" ) }
            </Box>
        </Dialog>
    )
}

export default ErrorDialog