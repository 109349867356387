
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import {
  slice as uiSlice
} from "./slices/ui/slice"

import {
  slice as clientSlice
} from "./slices/client/slice"

import {
  trackerApi
} from "dal"
/*
import {
  apiApi as loggerApi,
  trackerApi as loggerTrackerApi,
} from "features/logger/dal"

import {
  api as mainApi
} from "@dal"

import {
  slice as loggerSlice,
} from "features/logger/store"

import {
  slice as drainingSlice,
} from "./slices/draining.slice"
*/

export const rootReducers = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [clientSlice.name]: clientSlice.reducer,
  //dal
  [trackerApi.reducerPath]: trackerApi.reducer,
  /*
    [uiSlice.name]: uiSlice.reducer,
    [drainingSlice.name]: drainingSlice.reducer,
    // dal
    [loggerApi.reducerPath]: loggerApi.reducer,
    [loggerTrackerApi.reducerPath]: loggerTrackerApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    */
})

export const store = configureStore({
  reducer: rootReducers,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat([
        trackerApi.middleware
        /*loggerApi.middleware,
        loggerTrackerApi.middleware,
        mainApi.middleware,*/
      ])
  ),
  devTools: true,
})

setupListeners(store.dispatch)

export default store