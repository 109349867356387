
import Box from "@mui/material/Box"


export default ({
    children,
}) => {
    return(
        <Box className="_cont" sx={{
            flexGrow: "1",
            paddingX: {
                xs: "2rem",
                md: "3rem",
            },
            width: "100%",//
            maxWidth: {
                xs: "123rem",
            },
            marginX: {
                xs: "auto",
            },
        }}>
            { children }
        </Box>
    )
}