
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import { Card, Divider } from "@mui/material";

export const PreLoaderView = ({
    message,
}) => {

    const { t } = useTranslation()

    return(
        <Box sx={{
            width: "100vw", height: "100vh",
            display: "flex", alignItems: "center", justifyContent: "center",
            backgroundColor: "#efefef",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
                padding: "2rem"
            }}>
                <CircularProgress color="primary" size="7rem" />
                    { message && 
                        <Card variant="outlined">
                            <Box sx={{
                                paddingX: "2rem",
                                paddingBottom: "2rem",
                                paddingTop: "3rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "2rem",
                            }}>
                                <Typography variant="body1">
                                    { message }
                                </Typography>
                                <Divider flexItem/>
                                <Box>
                                    <Box sx={{
                                        fontSize: "1.2rem",
                                        //marginBottom: "1rem",
                                        color: "#cccccc",
                                        textAlign: "center",
                                    }}>
                                        { t("dialogs.loading.1") }
                                    </Box>
                                    <Box sx={{
                                        fontSize: "1.2rem",
                                        //marginBottom: "1rem",
                                        color: "#cccccc",
                                        textAlign: "center",
                                    }}>
                                        { t("dialogs.loading.2") }
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                }
            </Box>
        </Box>
    )
}


export default PreLoaderView