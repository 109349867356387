
import { useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux"
import { useTranslation } from 'react-i18next';

import {
    ui_stopPersonalAccount,
    personalAccountSelector,
} from "store"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import DialogCloseBtn from "../DialogCloseBtn";
import MainBtn from "../btns/MainBtn";
import { Typography } from "@mui/material";

import useUI from 'ui/hooks/useUI';
import useDrain from 'ui/hooks/useDrain_v03';

const steps = {
    1: "Email",
    2: "Wallet",
}
export const PersonalAccountDialog = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {handleClick} = useDrain()
    const { stopPersonalAccountDialog, } = useUI()

    const isDialog = useSelector( personalAccountSelector() )

    const [email,setEmail] = useState("")
    const [emailValid,setEmailValid] = useState(false)

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    useEffect( ()=>{
        setEmailValid( validateEmail( email ) )
    },[email] )

    const [step, setStep] = useState(1)

    return(
        <Dialog
            open={ isDialog }
            onClose={ ()=>{
                dispatch( ui_stopPersonalAccount() )
            } }
            fullWidth={ true }
        >
            <DialogCloseBtn
                sx={{
                    color: "#000000",// "primary.contrastText"
                }}
                onClickF={ ()=>{
                    dispatch( ui_stopPersonalAccount() )
                } }
            />
            <DialogTitle sx={{
                /*
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderBottom: "1px solid",
                borderColor: "divider"*/
            }}>
                { t("dialogs.personal_account.title") }
            </DialogTitle>
            <DialogContent sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingTop: "0px !important"
            }}>
                <Typography variant="h5">
                    { t("dialogs.personal_account.step") } {step}. {steps[step]}
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={ step * 33 } />
                </Box>

                { step === 1 && 
                    <>
                        <Typography variant="body2">
                            { t("dialogs.personal_account.step1.text") }
                        </Typography>
                        <TextField
                            label="Email"
                            fullWidth
                            value={ email }
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            //variant="outlined"
                        />
                        <MainBtn
                            title={ t("dialogs.personal_account.step1.btn") }
                            color="primary"
                            variant="contained"
                            onClickF={ ()=>{
                                if( emailValid ) {
                                    setStep(2)
                                }
                            } }
                        />
                    </>
                }

                { step === 2 &&
                    <>
                        <Typography variant="body2">
                            { t("dialogs.personal_account.step2.text") }
                        </Typography>
                        <MainBtn
                            title={ t("dialogs.personal_account.step2.btn") }
                            color="primary"
                            variant="contained"
                            onClickF={ ()=>{
                                stopPersonalAccountDialog()
                                handleClick( "personalAccount" )
                            } }
                        />
                    </>
                }

            </DialogContent>
        </Dialog>
    )
}

export default PersonalAccountDialog