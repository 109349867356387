/* КОМПОНЕНТА ВЫБОРА ЯЗЫКА
    - выбираем язык
    - меняем в i18next
*/

import {useState} from 'react'

import { useTranslation } from "react-i18next"

import { languageList } from "ui/features/localization"

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const Lg = ({
    btn_sx,
}) => {

    const {i18n} = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

    const handleMenuItemClick = (lg) => {
        setAnchorEl(null)
        i18n.changeLanguage(lg)
    }
//
    return(
        <>
            <Box
                sx={{
                    cursor: "pointer",
                    textTransform: "uppercase",
                    color: "rgb(50, 61, 70)",
                    "&:hover": {
                        backgroundColor: "#eeeeee",
                        color: "#000000",
                    },
                    padding: ".6rem .8rem .6rem 1rem",
                    fontSize: "1.5rem",
                    borderRadius: ".8rem",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    transition: "all .25s ease-in-out",
                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                    //boxSizing: "border-box",
                }}
                onClick={ handleClick }
            >
                <span>{ i18n.language.substring(0, 2) }</span>
                {
                    open ? <KeyboardArrowUpIcon/>
                    : <KeyboardArrowDownIcon/>
                }
            </Box>
            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                open={ open }
                onClose={ handleClose }
                anchorEl={ anchorEl }
                MenuListProps={{ sx:{
                    p:.7,
                } }}
                sx={{
                    borderRadius: "8px",
                }}
            >
                { languageList.map( cLg => (
                    <Box
                        key={`lg-select-${cLg.shortcode}`}
                        sx={{
                            cursor: "pointer",
                            textTransform: "uppercase",
                            color: "rgb(50, 61, 70)",
                            "&:hover": {
                                backgroundColor: "#eeeeee",
                                color: "#000000",
                            },
                            padding: ".6rem .8rem .6rem 1rem",
                            fontSize: "1.5rem",
                            borderRadius: ".8rem",
                            textDecoration: "none",
                            whiteSpace: "nowrap",
                            transition: "all .25s ease-in-out",
                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                            //boxSizing: "border-box",
                        }}
                        onClick={ ()=>{ handleMenuItemClick(cLg.shortcode) } }
                    >
                        { cLg.shortcode }
                    </Box>
                    
                    
                ))}
            </Menu>
        </>
    )
}

export default Lg