/* список доступных языков в приложениии
    - список должен совпадать с public/locale/ ...
*/
export const languageList = [
    {
        shortcode: "en",
        title: "English",
    },
    {
        shortcode: "ru",
        title: "Русский",
    },
] 

/* язык по умолчанию
    - обязан быть в списке languageList
*/
export const defaultLg = "en"

const toExport = {
    languageList: languageList,
    defaultLg: defaultLg,
}
export default toExport
