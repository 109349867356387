
import {
    WORKER_GET_PARAM,
} from "ui/configs/main"

const get_worker_ref = ( get_param ) => {
    // settings
        const default_result = null // значение, если гет переменная не найдена
    // end settings
    const urlParams = new URLSearchParams( window.location.search );
    const result = urlParams.get( get_param )
    return result ? result : default_result
}

export const useWorker = () => {

    const getWorker = () => {
        return get_worker_ref( WORKER_GET_PARAM )
    }

    return getWorker
}

export default useWorker