import { Suspense } from 'react'
import LoadingFallback from './LoadingFallback'
import "../utils/i18n"

// mui localization
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const Localize = (props) => {
    return(
        <Suspense fallback={ <LoadingFallback /> }>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {props.children}
            </LocalizationProvider>
        </Suspense>
    )
}

export default Localize