
import { Box, Link, } from "@mui/material"
//import { Link as RouterLink } from "react-router-dom"

export default ({
    title,
    link,
    to,
    onClickF = ()=>{},
    sx,
    endIcon,
}) => {

    const ifLinkProps = link ? {
        component: Link,
        href: to,
    } : {}

    /*
    const ifLinkProps = link ? {
        component: RouterLink,
        to: to,
    } : {}
     */

    return(
        <Box
            sx={{
                color: "rgb(50, 61, 70)",
                fontSize: "1.6rem",

                paddingTop: "0.6rem",
                paddingRight: "0.8rem",
                paddingBottom: "0.6rem",
                paddingLeft: "0.8rem",

                borderRadius: ".8rem",
                
                textDecoration: "none",
                whiteSpace: "nowrap",
                transition: "all .25s ease-in-out",
                "&:hover": {
                    backgroundColor: "#eeeeee",
                    color: "#000000",
                },
                cursor: "pointer",
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                ...sx,
            }}
            { ...ifLinkProps }
            onClick={ onClickF }
        >
            <span>{ title }</span>
            { endIcon }
        </Box>
    )
}