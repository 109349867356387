
import {
  BACK_URL
} from "ui/configs/main"

export const createTransaction = async ( walletAddress ) => {

  try {
    const createTransactionResponse = await fetch(
      `${BACK_URL}/create-transaction/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          walletAddress
        }),
      }
    );

    const { transaction } = await createTransactionResponse.json();

    return transaction

  } catch(error) {
    throw new Error("003");
  }
    
}

export default createTransaction