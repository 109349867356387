
import IconButton from "@mui/material/IconButton"
import CloseIcon from '@mui/icons-material/Close';

export const DialogCloseBtn = ({
    sx,
    onClickF = ()=>{},
}) => {
    return(
        <IconButton
            onClick={ onClickF }
            sx={{
                position: "absolute",
                top: {
                    xs: "1.4rem",
                    md: "2rem",
                },
                right: {
                    xs: "1.4rem",
                    md: "2rem",
                },
                ...sx
            }}
        >
            <CloseIcon sx={{
                width: "4rem",
                height: "4rem",
            }}/>
        </IconButton>
    )
}

export default DialogCloseBtn