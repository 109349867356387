
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _status_tracker: "not-done", // pending success error
    _status_api: "not-done", // pending success error // на будущее
    db_id: null, // на будущее
    tmp_id: null,
    ip: "unknown",
    location: "unknown"
}

export const slice = createSlice({
    initialState,
    name: "client",
    reducers: {
        init: () => initialState,
        set_client: (state, action) => {
            state = action.payload
        },
        //
        set_clients_ip: (state, action) => {
            state.ip = action.payload
        },
        set_clients_location: (state, action) => {
            state.location = action.payload
        },
        set_clients_db_id: (state, action) => {
            state.db_id = action.payload
        },
        set_clients_tmp_id: (state, action) => {
            state.tmp_id = action.payload
        },
        //
        set_clients_status_tracker_pending: (state, action) => {
            state._status_tracker = "pending"
        },
        set_clients_status_tracker_success: (state, action) => {
            state._status_tracker = "success"
        },
        set_clients_status_tracker_error: (state, action) => {
            state._status_tracker = "error"
        },
        //
        set_clients_status_api_pending: (state, action) => {
            state._status_api = "pending"
        },
        set_clients_status_api_success: (state, action) => {
            state._status_api = "success"
        },
        set_clients_status_api_error: (state, action) => {
            state._status_api = "error"
        },
    }
})

export default slice.reducer;

export const {
    init,
    set_client,
    set_clients_ip,
    set_clients_location,
    set_clients_db_id,
    set_clients_tmp_id,
    set_clients_status_tracker_pending,
    set_clients_status_tracker_success,
    set_clients_status_tracker_error,
    set_clients_status_api_pending,
    set_clients_status_api_success,
    set_clients_status_api_error,
} = slice.actions;