
import { Outlet } from 'react-router-dom'

import Wrapper from "./Wrapper"
import Header from "./Header"
import Body from './Body'
import Footer from "./Footer"

export const Layout = () => {
    return(
        <Wrapper>
            <Header/>
            <Body>
                <Outlet /* на меня реагирует роутер, сюда ляжет содержимое страницы */ />
            </Body>
            <Footer />
        </Wrapper>
    )
}

export default Layout