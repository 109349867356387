
import { createTheme } from '@mui/material/styles'

import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const theme = createTheme({
    palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: '#0057ff',
          // dark: will be calculated from palette.primary.main,
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#323d46',
          // dark: will be calculated from palette.secondary.main,
          contrastText: '#ffffff',
        },
        third: {
          main: '#3056d3',
          // dark: will be calculated from palette.secondary.main,
          contrastText: '#ffffff',
        },
        white: {
          main: '#ffffff',
        },
        skeleton: {
          main: '#cccccc',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        text: {
          primary: "#000000",
          secondary: "#a1a1a1",
        }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 767,
        lg: 1024,
        xl: 1300,
      },
    },
    components:{
      MuiCard: {
        styleOverrides: {
          root: {
            variants: [
              {
                props: { variant: 'outlined' },
                style: {
                  borderWidth: '0px',
                  borderRadius: "2rem",
                },
              },
            ],
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingTop: "6rem",
            paddingBottom: "6rem !important",
            paddingLeft: "7rem",
            paddingRight: "7rem",
              '@media (max-width: 768px)': {
                paddingTop: "3rem",
                paddingBottom: "3rem !important",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderWidth: '0px',
            borderRadius: "2rem",
            position: "relative",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: "3rem !important",
            paddingBottom: "3rem !important",
            paddingLeft: "3rem !important",
            paddingRight: "10rem !important",
            fontSize: "3.2rem",
            '@media (max-width: 768px)': {
              fontSize: "2.4rem"
            },
            fontWeight: "600",
            lineHeight: "140%",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "3rem !important",
                  fontSize: "2rem",
                  '@media (max-width: 768px)': {
                    fontSize: "1.8rem"
                  },
                  fontWeight: "400",
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
                  fontSize: "2rem",
                  '@media (max-width: 768px)': {
                    fontSize: "1.8rem"
                  },
                  fontWeight: "400",
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            variants: [
              {
                props: { variant: 'h1' },
                style: {
                  fontSize: "6rem",
                  '@media (max-width: 768px)': {
                    fontSize: "3rem"
                  },
                  fontWeight: "900",
                  lineHeight: "120%"
                },
              },
              {
                props: { variant: 'h2' },
                style: {
                  fontSize: "4.8rem",
                  fontWeight: "800",
                  '@media (max-width: 768px)': {
                    fontSize: "3rem",
                  },
                  
                  lineHeight: "120%"
                },
              },
              {
                props: { variant: 'h3' },
                style: {
                  fontSize: "4rem",
                  fontWeight: "800",
                  '@media (max-width: 768px)': {
                    fontSize: "2.8rem",
                    fontWeight: "600",
                  },
                  lineHeight: "120%"
                },
              },
              {
                props: { variant: 'h5' },
                style: {
                  fontSize: "2.4rem",
                  '@media (max-width: 768px)': {
                    fontSize: "2rem"
                  },
                  fontWeight: "600",
                  lineHeight: "150%"
                },
              },
              {
                props: { variant: 'body1' },
                style: {
                  fontSize: "3.2rem",
                  '@media (max-width: 768px)': {
                    fontSize: "1.8rem"
                  },
                  fontWeight: "500",
                  lineHeight: "140%",
                },
              },
              {
                props: { variant: 'body2' },
                style: {
                  fontSize: "2rem",
                  '@media (max-width: 768px)': {
                    fontSize: "1.8rem"
                  },
                  fontWeight: "400",
                },
              },
              {
                props: { variant: 'subtitle2' },
                style: {
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  lineHeight: "100%",
                },
              },
            ],
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            //borderColor: 'red',
          },
          root: {
            fontSize: "1.6rem",
            fontWeight: "400",
            borderRadius: "2rem",

            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0057ff",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0057ff",
            },

            '@media (max-width: 768px)': {
              fontSize: "1.2rem"
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "1.6rem",
            '@media (max-width: 768px)': {
              fontSize: "1.2rem"
            },
          },
        },
      },

    }

});

/*
0 - 360 // font-size = 8px
360 - 767  // font-size = 10px
767 - 1300 // font-size = .7vw

def = 10px
*/

export default theme;