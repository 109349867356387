
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"

import Container from "ui/components/Container"

import img_serts_1 from "./serts_1.jpg"
import img_serts_2 from "./serts_2.jpg"

export const Footer = () => {
    return(
        <Box className="_footer" sx={{
            backgroundColor: "#000000",
            color: "primary.contrastText",
            flexGrow: "0",
            py:"4rem",
        }}>
            <Container>
                <Box sx={{
                    borderBottom: "1px solid hsla(0,0%,100%,.2)",
                    paddingBottom: "4rem"
                }}>
                    <Grid container columnSpacing="3rem" rowSpacing="3rem" >
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    md: "row"
                                },
                                //gap: "3rem",
                            }}>
                                <Box sx={{
                                    flex: "1 0 40%",
                                    mb: "3rem",
                                    display: "flex", flexDirection: "row",
                                    justifyContent: "flex-start", alignItems: "center",
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 33" aria-label="AMLNext" style={{ width:"12rem", height:"auto", }}>
                                        <path fill="#fff" fillRule="evenodd" d="M14.555 1.543c-1.43-2.105-4.566-2.046-5.915.112L.531 14.63a3.49 3.49 0 0 0 .037 3.759l8.487 13.014c1.39 2.13 4.526 2.13 5.915 0l8.462-12.975a3.49 3.49 0 0 0-.036-3.87L14.555 1.543Zm.968 7.687c-.305 5.437-4.595 9.816-10.012 10.294l6.502 9.97 8.462-12.974-4.952-7.29Z" clipRule="evenodd"></path>
                                    </svg>
                                    <Box sx={{
                                        textDecoration: "none",
                                        color: "#ffffff",
                                        fontWeight: "800",
                                        fontSize: "2rem",
                                        ml: "-9rem"
                                    }}>
                                        AMLNext
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: "1 0 60%"
                                }}>
                                    <Box sx={{
                                        fontSize: {
                                            xs: "1.2rem",
                                            md: "1.6rem",
                                        },
                                        color: "rgb(161, 161, 161)",
                                        textTransform: "uppercase",
                                        lineHeight: "140%",
                                        marginBottom: "1rem",
                                        fontWeight: "600",
                                    }}>
                                        Safelement Limited,
                                    </Box>
                                    <Box sx={{
                                        fontSize: {
                                            xs: "1rem",
                                            md: "1.4rem",
                                        },
                                        color: "rgb(161, 161, 161)",
                                        textTransform: "uppercase",
                                    }}>
                                        Office 1111, Suite 1102, Lee Garden One, 33 Hysan Avenue, Causeway Bay, Hong Kong
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    md: "row"
                                },
                                gap: "2rem",
                                justifyContent: "center",
                            }}>
                                <Box>
                                    <img src={ img_serts_1 } width="100px" />
                                </Box>
                                <Box>
                                    <img src={ img_serts_2 } width="100px" />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                    <Grid container columnSpacing="3rem" rowSpacing="3rem" mt="4rem">
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{
                                fontSize: {
                                    xs: "1rem",
                                    md: "1.4rem",
                                },
                                color: "rgb(161, 161, 161)",
                            }}>
                                © 2024 AMLNext
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{
                                fontSize: {
                                    xs: "1rem",
                                    md: "1.4rem",
                                },
                                color: "rgb(161, 161, 161)",
                                textAlign: {
                                    xs: "left",
                                    md: "center",
                                },
                            }}>
                                User Agreement&nbsp;&nbsp;&nbsp;Privacy Policy
                            </Box>
                        </Grid>
                    </Grid>
            </Container>
        </Box>
    )
}

export default Footer