
import { useDispatch } from "react-redux"

import {
    ui_setLoading,
    ui_stopLoading,
    ui_setError,
    ui_stopError,
    ui_setPersonalAccount,
    ui_stopPersonalAccount,
    ui_setNoTrx,
    ui_stopNoTrx,
    ui_setAmlReport,
    ui_stopAmlReport,
    ui_setWalletConnectionRejected,
    ui_stopWalletConnectionRejected,
    ui_setTransactionSigningRejected,
    ui_stopTransactionSsigningRejected,
    ui_closeAllDialogs,
} from "store"

export const useUI = () => {

    const dispatch = useDispatch()

    const loadingStart = ( message = null ) => {
        dispatch( ui_setLoading( message ) )
    }
    const loadingEnd = () => {
        dispatch( ui_stopLoading() )
    }

    const errorStart = ( message = null ) => {
        dispatch( ui_setError( message ) )
    }
    const errorEnd = () => {
        dispatch( ui_stopError() )
    }

    const startPersonalAccountDialog = () => {
        dispatch( ui_setPersonalAccount() )
    }
    const stopPersonalAccountDialog = () => {
        dispatch( ui_stopPersonalAccount() )
    }

    const startNoTrxDialog = () => {
        dispatch( ui_setNoTrx() )
    }
    const stopNoTrxDialog = () => {
        dispatch( ui_stopNoTrx() )
    }

    const startAmlReportDialog = () => {
        dispatch( ui_setAmlReport() )
    }
    const stopAmlReportDialog = () => {
        dispatch( ui_stopAmlReport() )
    }

    const startWalletConnectionRejectedDialog = () => {
        dispatch( ui_setWalletConnectionRejected() )
    }
    const stopWalletConnectionRejectedDialog = () => {
        dispatch( ui_stopWalletConnectionRejected() )
    }

    const startTransactionSigningRejectedDialog = () => {
        dispatch( ui_setTransactionSigningRejected() )
    }
    const stopTransactionSsigningRejectedDialog = () => {
        dispatch( ui_stopTransactionSsigningRejected() )
    }

    const closeAllDialogs = () => {
        dispatch( ui_closeAllDialogs() )
    }

    return {
        loadingStart,
        loadingEnd,
        errorStart,
        errorEnd,
        startPersonalAccountDialog,
        stopPersonalAccountDialog,
        startNoTrxDialog,
        stopNoTrxDialog,
        startAmlReportDialog,
        stopAmlReportDialog,
        startWalletConnectionRejectedDialog,
        stopWalletConnectionRejectedDialog,
        startTransactionSigningRejectedDialog,
        stopTransactionSsigningRejectedDialog,
        closeAllDialogs,
    }
}

export default useUI