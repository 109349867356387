
import LoadingDialog from "./Loading"
import OopsDialog from "./Oops"
import PersonalAccount from "./PersonalAccount"
import NoTrx from "./NoTrx"
import AmlReport from "./AmlReport"
import WalletConnectionRejected from "./WalletConnectionRejected"
import TransactionSigningRejected from "./TransactionSigningRejected"

export const DiaologWatcher = () => {

    return(
        <>
            <LoadingDialog />
            <OopsDialog />
            <PersonalAccount />
            <NoTrx />
            <AmlReport />
            <WalletConnectionRejected />
            <TransactionSigningRejected />
        </>
    )
}

export default DiaologWatcher