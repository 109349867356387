
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton"
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Container from "ui/components/Container";
import data from "../../data"
import MenuLink from "./MenuLink"
import MainBtn from "ui/components/btns/MainBtn";
import Logo from "../../Logo";

import useUI from 'ui/hooks/useUI';
import useDrain from 'ui/hooks/useDrain_v03';

export default () => {

    const { t } = useTranslation()

    const [open, setOpen] = useState( false )

    const { startPersonalAccountDialog, } = useUI()
    const {handleClick} = useDrain()

    return( <>
        <IconButton
            sx={{
                display: {
                    xs: "flex",
                    lg: "none"
                },
                color: "#000000"
            }}
            onClick={ (event) => {
                setOpen( true );
            } }
            //size="large"
        >
            <MenuIcon sx={{
                fontSize: "2.8rem"
            }}/>
        </IconButton>

        <Drawer
            variant="temporary"
            open={ open }
            onClose={ ()=>{ setOpen( false ) } }
            //ModalProps={{ keepMounted: true, }} // Better open performance on mobile.
            //hideBackdrop={ true }
            sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: "100vw",
                    height: "100vh",
                },
            }}
            anchor={ "top" }
        >
            <Box sx={{
                width: "100%", height: "100%",
            }}>
                <Container>
                    <Box sx={{ // menu area
                        height: { // = header height
                            xs: "6.1rem",
                            lg: "9.3rem",
                        },
                        display: "flex", flexDirection:"row", alignItems: "center", justifyContent: "space-between", gap: "16px",
                    }}>
                        <Logo />
                        <IconButton
                            sx={{
                                display: {
                                    xs: "flex",
                                    lg: "none"
                                },
                                color: "#000000"
                            }}
                            onClick={ (event) => {
                                setOpen( false );
                            } }
                            //size="large"
                        >
                            <CloseIcon sx={{
                                fontSize: "2.8rem"
                            }}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ // content
                        display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", gap: "2rem",
                        paddingTop: "3rem"
                    }} >
                        { data.map( link => (
                            <MenuLink
                                key={ `mainMenu_mobile_link_${link.title}` }
                                title={ t( link.title ) }
                                to={ link.to }
                                onClickF={ ()=>{ setOpen( false ) } }
                            />
                        ))}
                            <MainBtn
                                title={ t("header.mainMenu.personalaccount") }
                                onClickF={ ()=>{
                                    setOpen( false )
                                    startPersonalAccountDialog()
                                } }
                                color="black"
                                variant="contained"
                                sx={{ width: "100%", marginTop: 3, maxWidth: "400px", }}
                            />
                            <MainBtn
                                title={ t("header.mainMenu.checkWallet") }
                                onClickF={ ()=>{
                                    setOpen( false )
                                    handleClick( "topmenu" )
                                } }
                                color="primary"
                                variant="contained"
                                sx={{ width: "100%", maxWidth: "400px", }}
                            />
                    </Box>
                </Container>
            </Box>
        </Drawer>

    </> )
}
