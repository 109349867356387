

import { Box } from "@mui/material"

const colors = {
    normal: {
        primary: {
            color: "primary.main",
            "&:hover": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
            }
        },
        secondary: {
            color: "secondary.main",
            "&:hover": {
                backgroundColor: "secondary.main",
                color: "secondary.contrastText",
            }
        },
        black: {
            color: "#000000",
            "&:hover": {
                backgroundColor: "#000000",
                color: "#ffffff",
            }
        },
    },
    contained: {
        primary: {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
                backgroundColor: "third.main",
                color: "third.contrastText",
            }
        },
        secondary: {
            backgroundColor: "secondary.main",
            color: "secondary.contrastText",
            "&:hover": {
                backgroundColor: "#000000",
                color: "#ffffff",
            }
        },
        black: {
            backgroundColor: "#000000",
            color: "#ffffff",
            "&:hover": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
            }
        },
    },
}

const sizes = {
    small: {},
    normal: {
        height: "4.7rem",
        paddingX: "2.4rem",
        borderRadius: "5rem",

        fontSize: "1.6rem",
        fontWeight: "600",
    },
    big: {
        height: "7rem",
        paddingX: "4rem",
        borderRadius: "5rem",

        fontSize: "2rem",
        fontWeight: "600",
    },
}

export default ({
    size = "normal", // small big
    title = "title",
    variant = "normal", // contained
    color = "primary", // secondary black
    sx,
    onClickF = ()=>{},
}) => {

    return(
        <Box
            sx={{
                cursor: "pointer",
                display: "inline-flex", alignItems: "center", justifyContent: "center",

                ...colors[variant][color],
                ...sizes[size],
                ...sx,
            }}
            onClick={ onClickF }
        >
            {title}
        </Box>
    )
}