
import UI from "./ui/UI.js"
//import UI from "./ui_maloi/UI"

import { ReduxProvider } from "store"

function App() {
  return (
    <ReduxProvider>
      <UI />
    </ReduxProvider>
  );
}

export default App;
