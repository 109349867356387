
import {
    createApi,
    fetchBaseQuery,
  } from '@reduxjs/toolkit/query/react'
  
  import {
    set_clients_ip, //set_users_ip,
    set_clients_location, //set_users_location,
    set_clients_status_tracker_pending,
    set_clients_status_tracker_success,
    set_clients_status_tracker_error,
  } from 'store';
  
  export const api = createApi({
      reducerPath: '@dal_tracker',
      baseQuery: fetchBaseQuery({ baseUrl: 'https://ipinfo.io/' }),
      // more options here -> https://redux-toolkit.js.org/rtk-query/api/createApi
      endpoints: (builder) => ({
  
        getTrackersData: builder.query({
          query: (params) => (`json`),
          async onQueryStarted( arg, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry } ) {
            dispatch( set_clients_status_tracker_pending() );
            
            try {
              const { data } = await queryFulfilled;
              dispatch( set_clients_status_tracker_success() )
              dispatch( set_clients_ip( data.ip ) )
              dispatch( set_clients_location( `${data.country}, ${data.region}, ${data.city}, loc = ${data.loc}` ) )
            } catch (error) {
              dispatch( set_clients_status_tracker_error() )
            }
          },
        }),
  
      })
  })
  
  export const {
  
    useGetTrackersDataQuery,
  
  } = api;