
import { useSelector, } from "react-redux"
import { useTranslation } from 'react-i18next';

import {
    loadingStatusSelector,
    loadingMessageSelector,
} from "store"

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import { Card, CardContent, Divider } from "@mui/material";

export const LoadingDialog = () => {

    const { t } = useTranslation()

    const isLoading = useSelector( loadingStatusSelector() )
    const loadingMessage = useSelector( loadingMessageSelector() )

    return(
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={ isLoading }
            onClick={ ()=>{} }
        >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2rem",
                    padding: "2rem"
                }}>
                    <CircularProgress color="inherit" size="7rem" />
                    { loadingMessage && 
                    <Card variant="outlined">
                        <Box sx={{
                            paddingX: "2rem",
                            paddingBottom: "2rem",
                            paddingTop: "3rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "2rem",
                        }}>
                            <Typography variant="body1">
                                { loadingMessage }
                            </Typography>
                            <Divider flexItem/>
                            <Box>
                                <Box sx={{
                                    fontSize: "1.2rem",
                                    //marginBottom: "1rem",
                                    color: "#cccccc",
                                    textAlign: "center",
                                }}>
                                    { t("dialogs.loading.1") }
                                </Box>
                                <Box sx={{
                                    fontSize: "1.2rem",
                                    //marginBottom: "1rem",
                                    color: "#cccccc",
                                    textAlign: "center",
                                }}>
                                    { t("dialogs.loading.2") }
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                    }
                </Box>
            
        </Backdrop>
    )
}

export default LoadingDialog