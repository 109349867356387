
import Box from "@mui/material/Box"

export const Body = ({children}) => {
    return(
        <Box className="_body" sx={{
            flexGrow: "1",
            /*mt: {
                xs: "6.1rem",
                lg: "9.3rem",
            },*/
            backgroundColor: "#efefef",
        }}>
            <Box sx={{
                width: "100vw",
                maxWidth:"100%",
                overflowX: "hidden",
                paddingBottom: "12rem",
            }}>
                { children }
            </Box>
        </Box>
    )
}

export default Body