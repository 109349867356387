
import { Box } from "@mui/material"

import { useTranslation } from 'react-i18next';

import data from "../../data"

import HeaderBtn from "./HeaderBtn";

export default () => {

    const { t } = useTranslation()

    return(
        <Box sx={{
            flexGrow: "1",
            display: {
                xs: "none",
                lg: "flex",
            },
            flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "8px",
        }}>
            { data.map( el => (
                <HeaderBtn
                    key={ `mainMenuLink_${el.to}` }
                    title={ t( el.title ) }
                    to={ el.to }
                    link
                />
            ) )}
        </Box>
    )
}