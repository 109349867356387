
import Button from "@mui/material/Button"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

export const OnErrorDialog = ({
    open,
    setOpen = ()=>{},
    children,
}) => {
    return(
        <Dialog
                open={ open }
                onClose={ ()=>{
                    setOpen( false )
                } }
                fullWidth={ true }
                maxWidth={ "xs" }
                /*PaperProps={{
                    sx: {
                        backgroundColor: "primary.main",
                        color: "primary.contrastText"
                    }
                }}*/
            >
                { children }
                <DialogActions>
                    <Button
                        onClick={()=>{
                            setOpen( false )
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                    >
                        close
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default OnErrorDialog