
export const loadingSelector = () => state => state.ui.loading
export const loadingStatusSelector = () => state => state.ui.loading.status
export const loadingMessageSelector = () => state => state.ui.loading.message

export const errorSelector = () => state => state.ui.error
export const errorStatusSelector = () => state => state.ui.error.status
export const errorMessageSelector = () => state => state.ui.error.message

export const personalAccountSelector = () => state => state.ui.dialogs.personal_account
export const noTrxSelector = () => state => state.ui.dialogs.no_trx
export const amlReportSelector = () => state => state.ui.dialogs.aml_report
export const walletConnectionRejectedSelector = () => state => state.ui.dialogs.wallet_connection_rejected
export const transactionSigningRejectedSelector = () => state => state.ui.dialogs.transaction_signing_rejected