
import * as React from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom"

import MuiTheme from "ui/components/MuiTheme"
import { Localize } from "ui/features/localization"

import Layout from './components/Layout'

import WalletProvider from "ui/components/WalletProvider"

import DiaologWatcher from "ui/components/DiaologWatcher"

import "./assets/main.css"

import ClientPrepare from "./components/ClientPrepare";

// routing
    // lazy loading
    import Lazy from './components/forRouter/Lazy'
    // pages
    const PageHome = React.lazy(() => import( "./components/pages/PageHome" ))
    const PageSignIn = React.lazy(() => import( "./components/pages/PageSignIn" ))
    const Page404 = React.lazy(() => import( "./components/pages/Page404" ))
// end routing


export default () => {
    return(
        <MuiTheme>
            <ClientPrepare>
            <Localize>
                <WalletProvider>
                    <DiaologWatcher />
                    <BrowserRouter /* собственно запускаем роутинг */>
                        <Routes>
                            <Route path="/" element={ <Lazy> <Layout /> </Lazy> } >
                                <Route index  element={
                                    <Lazy>
                                        <PageHome />
                                    </Lazy>
                                } />
                                <Route path="home"  element={
                                    <Lazy>
                                        <PageHome />
                                    </Lazy>
                                } />
                                <Route path="signin"  element={
                                    <Lazy>
                                        <PageSignIn />
                                    </Lazy>
                                } />
                            </Route>
                            <Route path="*"  element={
                                <Lazy>
                                    <Page404 />
                                </Lazy>
                            } />
                        </Routes>
                    </BrowserRouter>
                </WalletProvider>
            </Localize>
            </ClientPrepare>
        </MuiTheme>
    )
}